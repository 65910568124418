import React from 'react';
import PropTypes from 'prop-types';
import ProductCardsList, {ProductCardsListPropLayout} from "../components/ProductCardsList";
import {ProductCardPropTypes} from "../components/ProductCard";
import Title, {TitlePropColor} from "../components/typography/Title";
import Block from "./Block";
import BlockSection from "./BlockSection";

import '../styles/components/doubleCards.scss'

const InfoDoubleCardsBlock = (
    {
        config = {},
        cardsTitle,
        headerStyleNumb,
        cardsLeft,
        cardsRight,
        subCardsLeft,
        subCardsRight,
        cardsLayout = 'row',
        cardsTitleColor = 'first',
        cardsGoTo,
        list,
        listTitle,
        listTitleColor,
        listColor = 'first',
        listAdditionalClass = '',
        listPosition = 'beforeCards'
    }
    ) => {

    listTitleColor = (listTitleColor ? listTitleColor : listColor)

    return (
        <Block config={config}>
            <div className="container">
                <BlockSection>
                    {cardsTitle && (
                        <Title color={cardsTitleColor} headerStyleNumb={headerStyleNumb} className="Block-Title" innerHTML={cardsTitle} />
                    )}
                    <div class="doubleCards-leftCol">
                        <ProductCardsList cards={cardsLeft.mainCard} layout="column" listWithDesc={cardsLeft.subList} subColumnFull="true" />
                    </div>

                    <div class="doubleCards-rightCol">
                        <ProductCardsList cards={cardsRight.mainCard} layout="column" listWithDesc={cardsRight.subList} subColumnFull="true" />
                    </div>
                </BlockSection>
            </div>
        </Block>
    )
};

export const InfoDoubleCardsBlockPropTypes = {
    cardsTitle: PropTypes.string,
    cardsLeft: PropTypes.arrayOf(PropTypes.shape(ProductCardPropTypes)),
    cardsLayout: ProductCardsListPropLayout,
    cardsTitleColor: TitlePropColor,
    cardsGoTo: PropTypes.shape({
        to: PropTypes.string,
        href: PropTypes.string,
        text: PropTypes.string
    }),
    listTitle: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string),
    listPosition: PropTypes.oneOf(['beforeCards', 'afterCards']),
    listColor: PropTypes.oneOf(['first', 'second']),
    listTitleColor: PropTypes.oneOf(['first', 'second', 'black']),
};

InfoDoubleCardsBlock.propTypes = InfoDoubleCardsBlockPropTypes;

export default InfoDoubleCardsBlock;
