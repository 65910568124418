import React from 'react';

import Layout from "../../layout/Layout";
import PageHeaderBlock from "../../blocks/PageHeaderBlock";
import SEO from "../../components/seo";
import InfoCardsBlock from "../../blocks/InfoCardsBlock";
import SubmitApplicationBlock from "../../blocks/static/SubmitApplicationBlock";
import DoubleNumberedBlock from "../../blocks/static/DoubleNumberedBlock";
import InfoDoubleCardsBlock from "../../blocks/InfoDoubleCardsBlock";
import InfoDoubleCardsBlock2 from "../../blocks/InfoDoubleCardsBlock2";

import enMessages from '../../i18n/sbp/en.js'
import ruMessages from '../../i18n/sbp/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const PayoutPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO title={messages[locale].seo.title}
                 description={messages[locale].seo.description}
            />
            <PageHeaderBlock
                locale={locale}
                title={messages[locale].pageHeaderBlock.title}
                text={messages[locale].pageHeaderBlock.text}
            />

            <InfoDoubleCardsBlock
                haveQuestions={true}
                cardsTitle={messages[locale].infoCardsBlockFirst.listTitle}
                cardsTitleColor="black"
                cardsLeft={messages[locale].infoCardsBlockFirst.leftcolumn}
                cardsRight={messages[locale].infoCardsBlockFirst.rightcolumn}
                config={{
                    symmetric: true
                }}
            />

            <DoubleNumberedBlock locale={locale}/>

            <InfoDoubleCardsBlock2
                haveQuestions={true}
                cardsTitle={messages[locale].infoCardsBlockSecond.listTitle}
                cardsTitleColor="grey"
                cardsLeft={messages[locale].infoCardsBlockSecond.leftcolumn}
                cardsRight={messages[locale].infoCardsBlockSecond.rightcolumn}
                subCardsLeft={messages[locale].infoCardsBlockSecond.leftcolumn}
                subCardsRight={messages[locale].infoCardsBlockSecond.rightcolumn}
                config={{
                    symmetric: true
                }}
            />

            <InfoCardsBlock
                haveQuestions={true}
                listTitle={messages[locale].infoCardsBlockThird.listTitle}
                listTitleColor="black"
                list={messages[locale].infoCardsBlockThird.list}
                listColor="second"
                listGoTo={messages[locale].infoCardsBlockThird.listGoTo}
                config={{
                    symmetric: true
                }}
            />
            <SubmitApplicationBlock
                title={messages[locale].submitApplicationBlock.title}
                textSubmit={messages[locale].submitApplicationBlock.textSubmitBottom || messages[locale].submitApplicationBlock.textSubmit}
            />
        </Layout>
    );
};

export default PayoutPage;
