import iconDelivery from "../../images/icons/styled/first/Delivery.svg";
import iconTraining from "../../images/icons/styled/first/Training.svg";
import iconUserCheck from "../../images/icons/styled/first/UserCheck.svg";
import imageScheme from "../../images/schemes/payouts_scheme.svg";
import iconLightning from "../../images/icons/styled/first/Lightning.svg";
import iconCheck from "../../images/icons/styled/first/CheckInCircle.svg";
import iconShield from "../../images/icons/styled/first/Shield.svg";
import iconAPI from "../../images/icons/styled/first/API.svg";
import sbp1icon from "../../images/icons/styled/first/SBP1.svg";
import sbp2icon from "../../images/icons/styled/first/SBP2.svg";
import sbp1check from "../../images/icons/styled/first/SBPCheck.svg";
import sbp2check from "../../images/icons/styled/second/SBPCheck.svg";
import sbpfreelance from "../../images/icons/styled/first/SBP-freelance.svg";
import sbpcashback from "../../images/icons/styled/first/SBP-cashback.svg";
import sbpphone from "../../images/icons/styled/first/SBP-phone.svg";
import sbpearth from "../../images/icons/styled/first/SBP-earth.svg";

import links from "../../utils/links";


export default {
    "seo": {
        "title": "Выплаты самозанятым и физлицам по номеру телефона на банковские счета | Банк 131",
        "description": "Массовые выплаты физлицами и самозанятым через СБП. Решение для платформ онлайн обучения, платформ для фрилансеров. Для международных компаний – выплаты исполнителям в России. Прямая интеграция с ФНС, быстрые выплаты по номеру телефона."
    },
    "pageHeaderBlock": {
        "title": "Принимайте и отправляйте платежи через СБП",
        "text": "Отправляйте выплаты по номеру телефона и принимайте платежи от клиентов с помощью QR-кода."
    },

    "infoCardsBlockFirst": {
        "listTitle": "Система быстрых платежей – удобное решение для вашего бизнеса",
        "leftcolumn": {
            "mainCard": [
                {
                    "title": 'Выплаты самозанятым',
                    "text": 'Отправляйте денежные переводы физлицам по номеру телефона',
                    "icon": sbp1icon,
                    "theme": 'withoutBackground',
                    "listWithDesc": [
                          {
                              "title": 'Минимум реквизитов',
                              "text": 'Для платежа необходимы только номер телефона и ID банка. Больше никаких случайных ошибок при оплате из-за большого количества реквизитов.'
                          },
                          {
                              "title": 'Моментальные расчеты с физлицами',
                              "text": 'Денежные средства поступают клиентам и исполнителям на счет моментально. Выплаты через СБП выгодно отличаются от других видов переводов, где зачисления можно ожидать несколько дней.'
                          }
                    ]
                }
            ],

        },
        "rightcolumn": {
            "mainCard": [
                {
                    "title": 'Прием платежей',
                    "text": 'Принимайте оплату от клиентов с помощью QR-кодов и платежных ссылок',
                    "icon": sbp2icon,
                    "theme": 'withoutBackground',
                    "listWithDesc": [
                          {
                              "title": 'Оплата без границ',
                              "text": 'Размещайте QR-коды на сайте, баннерах, в email-рассылках и офлайн магазинах. Cоздавайте платежные ссылки для соцсетей и оплаты в мобильных приложениях.'
                          },
                          {
                              "title": 'Удобно для клиента – просто для бизнеса',
                              "text": 'Быстрое подключение, легкая интеграция по API и простое создание платежных инструментов: QR-кодов и диплинков. '
                          }
                    ]
                }
            ]
        }
    },

    "doubleNumberedBlock": {
        "commonTitle": "Как это работает?",

        "title1": "Выплаты",
        "listpoint1_item1": "Пользователь указывает номер телефона и ID банка на сайте или в приложении",
        "listpoint1_item2": "Нажимает на кнопку «Получить оплату» ",
        "listpoint1_item3": "Выплата происходит на банковский счет пользователя",

        "title2": "Прием платежей",
        "listpoint2_item1": "Покупатель сканирует QR-код, нажимает на кнопку или платежную ссылку",
        "listpoint2_item2": "Покупатель выбирает банк и подтверждает платеж. Денежные средства моментально поступают на счет продавца. ",
    },

    "infoCardsBlockSecond": {
        "listTitle": "Кому подойдет?",
        "listColor": "gray",
        "leftcolumn": {
            "mainCard": [
                {
                    "title": 'Онлайн бизнесу, который работает с физлицами ',
                    "text": 'Курьерским службам, таксопаркам и кэшбек-сервисам. А также компаниям, которые работают с фрилансерами и дистанционными специалистами. ',
                    "icon": sbp1check,
                    "theme": 'withoutBackground',
                }
            ],
            "subCards": [
                {
                    "title": 'Биржам фрилансеров',
                    "text": 'Для оплаты работ дизайнеров, копирайтеров, фотографов, веб-мастеров и других наемных специалистов',
                    "icon": sbpfreelance,
                    "theme": 'cardFirst',
                },
                {
                    "title": 'Кэшбек-сервисам ',
                    "text": 'Для быстрых выплат клиентам <br /><br />',
                    "icon": sbpfreelance,
                    "theme": 'cardFirst',
                }
            ],
        },
        "rightcolumn": {
            "mainCard": [
                {
                    "title": 'Бизнесу, который принимает платежи онлайн',
                    "text": 'Маркетплейсам, интернет-магазинам, мобильным играм и развлекательным платформам.<br /><br />',
                    "icon": sbp2check,
                    "theme": 'withoutBackground',
                }
            ],
            "subCards": [
                {
                    "title": 'Мобильным приложениям',
                    "text": 'Для удобной оплаты, не выходя за пределы платформы<br /><br /><br />',
                    "icon": sbpearth,
                    "theme": 'cardFirst',
                },
                {
                    "title": 'Интернет-магазинам ',
                    "text": 'Для оплаты на сайте, в соцсетях, в мобильном приложении ',
                    "icon": sbpearth,
                    "theme": 'cardFirst',
                }
            ],
        }
    },

    "infoCardsBlockThird": {
        "listTitle": "Как подключить прием платежей и выплаты через СБП",
        "list": [
            'Отправить заявку на почту <a href="mailto:partners@131.ru" target="_blank" rel="noreferrer">partners@131.ru</a> или оставить свой контакт в форме обратной связи ниже ',
            'Подписать договор и открыть расчетный счет в Банке 131',
            `Интегрировать <a href="${links.ru.api}" target="_blank" rel="noreferrer"> API</a>`,
        ],
    },

    "submitApplicationBlock": {
        "title": "Оставить заявку",
        "textSubmit": "Подключиться"
    }
}