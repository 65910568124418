import React from 'react';
import Block from "../Block";
import BlockSection from "../BlockSection";
import Title from "../../components/typography/Title";

import enMessages from '../../i18n/sbp/en.js'
import ruMessages from '../../i18n/sbp/ru.js'

import '../../styles/lists/doubleNumbered.scss'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const PricingBlock = ({ locale }) => {

    return (
        <Block config={{
            baseBorder: true
        }}>
            <div className="container">
                <BlockSection>
                    <Title headerStyleNumb="2" level="h2" color="grey">{messages[locale].doubleNumberedBlock.commonTitle}</Title>
                    <div className="clearNegativeMargin">
                        <div className="DoubleNumberedList pricingCardsList">
                            <div className="PricingCard PricingCard_theme_first DoubleNumberedList_theme_first">
                                <div className="PricingCard-Tags">
                                    <div className="PricingCard-Tag">{messages[locale].doubleNumberedBlock.title1}</div>
                                </div>
                                <ul className="DoubleNumberedCard-List PricingCard-List">
                                    <li class="number number1">{messages[locale].doubleNumberedBlock.listpoint1_item1}</li>
                                    <li class="number number2">{messages[locale].doubleNumberedBlock.listpoint1_item2}</li>
                                    <li class="number number3">{messages[locale].doubleNumberedBlock.listpoint1_item3}</li>
                                </ul>
                            </div>
                            <div className="PricingCard PricingCard_theme_second DoubleNumberedList_theme_second">
                                <div className="PricingCard-Tags">
                                    <div className="PricingCard-Tag">{messages[locale].doubleNumberedBlock.title2}</div>
                                </div>
                                <ul className="DoubleNumberedCard-List PricingCard-List">
                                    <li class="number number1">{messages[locale].doubleNumberedBlock.listpoint2_item1}</li>
                                    <li class="number number2">{messages[locale].doubleNumberedBlock.listpoint2_item2}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </BlockSection>
            </div>
        </Block>
    )
};

export default PricingBlock;
