import iconDelivery from "../../images/icons/styled/first/Delivery.svg";
import iconTraining from "../../images/icons/styled/first/Training.svg";
import iconUserCheck from "../../images/icons/styled/first/UserCheck.svg";
import imageScheme from "../../images/schemes/payouts_scheme.svg";
import iconLightning from "../../images/icons/styled/first/Lightning.svg";
import iconCheck from "../../images/icons/styled/first/CheckInCircle.svg";
import iconShield from "../../images/icons/styled/first/Shield.svg";
import iconAPI from "../../images/icons/styled/first/API.svg";
import sbp1icon from "../../images/icons/styled/first/SBP1.svg";
import sbp2icon from "../../images/icons/styled/first/SBP2.svg";
import sbp1check from "../../images/icons/styled/first/SBPCheck.svg";
import sbp2check from "../../images/icons/styled/second/SBPCheck.svg";
import sbpfreelance from "../../images/icons/styled/first/SBP-freelance.svg";
import sbpcashback from "../../images/icons/styled/first/SBP-cashback.svg";
import sbpphone from "../../images/icons/styled/first/SBP-phone.svg";
import sbpearth from "../../images/icons/styled/first/SBP-earth.svg";

import links from "../../utils/links";


export default {
    "seo": {
        "title": "Payouts to contractors’ bank accounts via phone number | Bank 131",
        "description": "Mass payouts to self-employed contractors and employees via the FPS. Solutions for e-learning and freelance platforms. Enables foreign companies to pay contractors from Russia. Direct integration with the FNS, quick payouts to bank cards and accounts or wallets."
    },
    "pageHeaderBlock": {
        "title": "Send and receive payments via Faster Payments System ",
        "text": "Send payments by phone number and accept payments from customers using a QR code."
    },

    "infoCardsBlockFirst": {
        "listTitle": "Faster payments system is a convenient solution for your business",
        "leftcolumn": {
            "mainCard": [
                {
                    "title": 'Payouts to self-employed persons',
                    "text": 'Transfer money to individuals by their phone number',
                    "icon": sbp1icon,
                    "theme": 'withoutBackground',
                    "listWithDesc": [
                          {
                              "title": 'Less requisites',
                              "text": 'Only the phone number and bank ID are required to make a payment. No more accidental payment errors due to numerous details.'
                          },
                          {
                              "title": 'Instant settlements with individuals',
                              "text": 'Funds are transferred to your clients’ and contractors’ accounts instantly. Payments via Faster Payments System compare favorably with other types of transfers where you have to wait several days for the money to arrive.'
                          }
                    ]
                }
            ],

        },
        "rightcolumn": {
            "mainCard": [
                {
                    "title": 'Internet acquiring',
                    "text": 'Accept payments from customers using QR codes and payment links',
                    "icon": sbp2icon,
                    "theme": 'withoutBackground',
                    "listWithDesc": [
                          {
                              "title": 'Payments without borders',
                              "text": 'Place QR codes on your website, banners, and in email newsletters. Create payment links for social networks and payments in mobile applications.'
                          },
                          {
                              "title": 'Convenient for the client,  easy for the business',
                              "text": 'Quick connection, easy API integration, and easy creation of payment instruments like QR codes and deep links.'
                          }
                    ]
                }
            ]
        }
    },

    "doubleNumberedBlock": {
        "commonTitle": "How does it work?",

        "title1": "Payouts",
        "listpoint1_item1": "The user provides their phone number and bank ID on the site or in the app.",
        "listpoint1_item2": "The user clicks the «Get paid» button.",
        "listpoint1_item3": "The payment is made to the user's bank account.",

        "title2": "Internet acquiring",
        "listpoint2_item1": "The сustomer scans the QR-code, clicks a button or a payment link.",
        "listpoint2_item2": "The сustomer selects a bank and confirms the payment. The money is instantly credited to the merchant's account.",
    },

    "infoCardsBlockSecond": {
        "listTitle": "Who can benefit from Faster Payments System?",
        "listColor": "gray",
        "leftcolumn": {
            "mainCard": [
                {
                    "title": 'Online businesses working with individuals',
                    "text": 'Courier services, taxi fleets, cashback services, and companies that work with freelancers and remote contractors.',
                    "icon": sbp1check,
                    "theme": 'withoutBackground',
                }
            ],
            "subCards": [
                {
                    "title": 'Freelance Platforms',
                    "text": 'Pay for the work of designers, copywriters, photographers, webmasters, and other hired professionals',
                    "icon": sbpfreelance,
                    "theme": 'cardFirst',
                },
                {
                    "title": 'Cashback services',
                    "text": 'Make fast payments to clients <br /><br />',
                    "icon": sbpfreelance,
                    "theme": 'cardFirst',
                }
            ],
        },
        "rightcolumn": {
            "mainCard": [
                {
                    "title": 'Businesses that accept payments online',
                    "text": 'Marketplaces, online stores, mobile games, and entertainment platforms.<br /><br />',
                    "icon": sbp2check,
                    "theme": 'withoutBackground',
                }
            ],
            "subCards": [
                {
                    "title": 'Mobile applications',
                    "text": 'Enable convenient payments without leaving the platform<br /><br />',
                    "icon": sbpearth,
                    "theme": 'cardFirst',
                },
                {
                    "title": 'Online stores ',
                    "text": 'Enable payments on websites, social networks, and in mobile apps',
                    "icon": sbpearth,
                    "theme": 'cardFirst',
                }
            ],
        }
    },

    "infoCardsBlockThird": {
        "listTitle": "How to enable payment acceptance and payments through Faster Payments System",
        "list": [
            'Send an application to <a href="mailto:partners@131.ru" target="_blank" rel="noreferrer">partners@131.ru</a> or leave your contact information in the feedback form below.',
            'Sign the contract and open a corporate bank account with Bank 131.',
            `Integrate <a href="${links.ru.api}" target="_blank" rel="noreferrer">the API</a>`,
        ],
    },

    "submitApplicationBlock": {
        "textSubmit": "Get in touch"
    }
}